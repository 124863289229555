<template>
    <div class="title-container">
        <div class="title">{{ title }}</div>
        <button v-if="type == 'report' && isEdit" class="upload upload-btn-position" type="button"
            @click.stop.prevent="onClickUpload">上传检验报告</button>
        <button class="setting" :class="{ 'setting-edit': isEdit }" type="button" @click.stop.prevent="onClickSetting">{{
            isEdit ? '保存' : '编辑' }}</button>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        type: String,
        isEdit: Boolean
    },
    data() {
        return {

        }
    },
    computed: {
        isEditComputed: {
            get() {
                return this.isEdit
            },
            set(val) {
                this.$emit('update:isEdit', val)
            }
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        onClickSetting() {
            // this.isEditComputed = !this.isEditComputed;
            this.$emit('onClickSetting', this.isEditComputed);
        },
        onClickUpload() {
            this.$emit('onClickUpload');
        },
    },
}
</script>
<style scoped lang='scss'>
.title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    padding: 0 25px;
    box-sizing: border-box;

    .title {
        height: 22px;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        line-height: 22px;
    }

    .upload {
        width: 110px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        font-size: 14px;
        color: #2E6BE6;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #2E6BE6;
    }

    .upload-btn-position {
        margin-left: auto;
        margin-right: 15px;
    }

    .setting {
        width: 63px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        font-size: 14px;
        color: #2E6BE6;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #2E6BE6;
    }

    .setting-edit {
        color: #FFFFFF;
        background: #2E6BE6;
    }
}
</style>