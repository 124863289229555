<template>
    <div class="card-container">
        <button class="edit" @click.stop.prevent="onEditClick">编辑</button>
        <BaselineChartCardLeft :questionDetail="questionDetail" :title="list.find(el => el.value == type)?.text ?? ''">
        </BaselineChartCardLeft>
        <BaselineChartCardRight v-if="questionDetail" :questionDetail="questionDetail"></BaselineChartCardRight>
        <BaselineCardNoData v-else></BaselineCardNoData>
        <BaselineAlertChartEdit v-if="isShowEditAlert" :type="type" :data="data" @onCloseClick="onCloseClick" @onSucceed="onSucceed">
        </BaselineAlertChartEdit>
    </div>
</template>

<script>
import BaselineChartCardLeft from './baseline-chart-card-left.vue';
import BaselineChartCardRight from './baseline-chart-card-right.vue';
import BaselineCardNoData from '../baseline-card-no-data/index.vue';
export default {
    props: {
        questionDetail: Object,
        data: Array,
        type: Number,
        user: Object,
    },
    components: {
        BaselineChartCardLeft,
        BaselineChartCardRight,
        BaselineCardNoData,
        BaselineAlertChartEdit: () => import('@c/user-main/baseline/baseline-info-card/baseline-alert-chart-edit.vue'),
    },
    data() {
        return {
            isShowEditAlert: false,
            list: [
                {
                    text: '睡眠质量(PSQI)',
                    value: 4
                },
                {
                    text: '嗜睡问卷(ESS)',
                    value: 6
                }, {
                    text: '焦虑情绪(GAD-7)',
                    value: 7
                }, {
                    text: '抑郁情绪(PHQ-9)',
                    value: 8
                }, {
                    text: '躯体症状(PHQ-15)',
                    value: 9
                },
            ]
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        onEditClick() {
            this.isShowEditAlert = true
        },

        onCloseClick() {
            this.isShowEditAlert = false
        },
        async onSucceed(list) {
            try {
                const data = await this.$api.updateQuestionnaire({
                    userId: this.user?.id ?? null,
                    userAnswerList: list,
                    assessmentType: this.type
                })
                this.isShowEditAlert = false
                this.$emit('refresh')
                this.$toast.showGreen('保存成功')
            } catch (error) {
                this.$toast.showGreen(error)
            }
        }
    },
}
</script>
<style scoped lang='scss'>
.card-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 15px 25px;
    box-sizing: border-box;
    position: relative;

    .edit {
        position: absolute;
        top: 12px;
        right: 25px;
        width: 63px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        font-size: 14px;
        color: #2E6BE6;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #2E6BE6;
    }
}
</style>