<template>
    <div class="container">
        <BaselineInfoCardTitle :title="title" :type="type == 1 ? 'report' : 'questionnaire'" :isEdit.sync="isEdit"
            @onClickSetting="onClickSetting" @onClickUpload="onClickUpload"></BaselineInfoCardTitle>
        <template v-if="type == 1">
            <BaselineInfoCardReport ref="cardReportRef" v-show="showInspectionReport()" class="margin-t-10" :isEdit="isEdit"
                :reportData="data" :user="user" @onReportDataChange="onReportDataChange">
            </BaselineInfoCardReport>
            <BaselineCardNoData message="暂无检验报告" v-show="!showInspectionReport()"></BaselineCardNoData>
        </template>

        <template v-if="type == 2 || type == 3">
            <BaselineInfoCardList v-if="data" class="margin-t-10" :isEdit="isEdit" :data="data"
                :userAnswerList.sync="userAnswerList" @onClickEdit="onClickEdit"></BaselineInfoCardList>
            <BaselineCardNoData message="" v-else></BaselineCardNoData>
        </template>
        <baseline-alert-edit v-if="isShowEditAlert" @onCloseClick="onListEditAlertClose" @onSucceed="onListEditSucceed"
            :obj="alertObj"></baseline-alert-edit>
    </div>
</template>

<script>
import BaselineInfoCardTitle from './baseline-info-card-title.vue'
import BaselineInfoCardReport from './baseline-info-card-report.vue'
import BaselineInfoCardList from './baseline-info-card-list.vue'
import BaselineCardNoData from '../baseline-card-no-data/index.vue'
export default {
    components: {
        BaselineInfoCardTitle,
        BaselineInfoCardReport,
        BaselineInfoCardList,
        BaselineCardNoData,
        BaselineAlertEdit: () => import('@c/user-main/baseline/baseline-info-card/baseline-alert-edit.vue'),
    },
    props: {
        type: Number,
        data: [Object, Array],
        user: Object,
    },
    data() {
        return {
            title: '',
            isEdit: false,
            userAnswerList: [],
            reportFormData: {},
            isShowEditAlert: false,
            alertObj: null
        }
    },
    created() {
        if (this.type == 1) {
            this.title = '检验报告'
        } else if (this.type == 2) {
            this.title = '病史'
        } else if (this.type == 3) {
            this.title = '生活习惯'
        }
    },
    mouted() {

    },
    methods: {
        async onClickSetting(isEdit) {
            if (!isEdit) {
                this.isEdit = true
                this.reportFormData = {}
                return
            }
            if (this.type == 2 || this.type == 3) {
                try {
                    for (let i = 0; i < this.data.length; i++) {
                        const obj = this.data[i]
                        if (obj.answerType === 11) continue;
                        const findAnswer = this.userAnswerList.find(el => el.problemId === obj.id)
                        if (!findAnswer) continue;
                        let emptyTag = true
                        if (obj.answerType === 1 && !findAnswer.singleChoiceAnswerId) {
                            emptyTag = false
                        } else if (obj.answerType === 2 && findAnswer.manyChoiceAnswerId.length === 0) {
                            emptyTag = false
                        }
                        if (!emptyTag) {
                            this.$toast.showRed('请填写完全部问题后再保存')
                            return
                        }
                    }
                    const data = await this.$api.updateQuestionnaire({
                        userId: this.user?.id ?? null,
                        userAnswerList: this.userAnswerList,
                        assessmentType: this.type == 3 ? 2 : 3
                    })
                    this.isEdit = false;
                    this.$emit('refresh')
                    this.$toast.showGreen('保存成功')
                } catch (error) {
                    this.$toast.showGreen(error)
                }
            } else {
                if (Object.keys(this.reportFormData).length === 0) {
                    this.isEdit = false;
                    return
                }
                try {
                    const data = await this.$api.updateInspectionReport({
                        ...this.reportFormData,
                    })
                    this.reportFormData = {}
                    this.isEdit = false;
                    this.$emit('refresh')
                    this.$toast.showGreen('保存成功')
                } catch (error) {
                    this.$toast.showGreen(error)
                }
            }
        },
        onClickUpload() {
            this.isEdit = true
            console.log()
            this.$refs.cardReportRef?.show()
        },

        onReportDataChange(obj) {
            this.reportFormData = { ...obj }
        },

        onClickEdit(obj) {
            this.alertObj = obj
            this.isShowEditAlert = true;
        },
        onListEditAlertClose() {
            this.isShowEditAlert = false;
        },
        onListEditSucceed(list) {
            this.userAnswerList.splice(0, this.userAnswerList.length, ...list)
            this.isShowEditAlert = false;
        },
        showInspectionReport() {
            const hasReport = (this.data.inspectionReportFile && this.data.inspectionReportFile.length > 0) ||
                (this.data.sleepReport && this.data.sleepReport.length > 0) ||
                (this.data.sleepReportFile && this.data.sleepReportFile.length > 0)

            return this.data && hasReport
        }
    },
}
</script>
<style scoped lang='scss'>
.margin-t-10 {
    margin-top: 10px;
}

.container {
    padding: 15px 0;
}
</style>