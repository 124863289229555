<template>
  <div class="container" :style="{
    width: `${540*zoom}px`
  }">
    <div ref="chartContainer" :style="`width: 100%; height: ${300*zoom}px ;zoom:${1/zoom};transform:scale(${zoom});transform-origin:0 0`"></div>
  </div>
</template>

<script>
export default {
  props: {
    questionDetail: Object
  },
  watch:{
    questionDetail() {
      this.drawLine();
    }
  },
  data() {
    return {
      chart: null,
    }
  },
  created() {
  },
  mounted() {
    this.chart = this.$echarts.init(this.$refs.chartContainer);
    let that = this;
    window.onresize = () => {
      this.chart.resize();
    };
    this.drawLine();
  },
  destroyed() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  },
  computed: {
    zoom(){
      return this.$store.state.zoom
    },

    xAxis() {
      if (!this.questionDetail) {
        return []
      }
      if (this.questionDetail.assessmentType == 1) {
        return ['A', 'B', 'C', 'D', 'E', 'F', 'G']
      } else if (this.questionDetail.assessmentType == 2) {
        return ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧']
      } else if (this.questionDetail.assessmentType == 3) {
        return ['①', '②', '③', '④', '⑤', '⑥', '⑦']
      } else if (this.questionDetail.assessmentType == 4) {
        return ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨']
      } else if (this.questionDetail.assessmentType == 5) {
        return ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', '⑬', '⑭', '⑮']
      } else {
        return []
      }
    },
    chartData() {
      if (!this.questionDetail) {
        return []
      }
      return this.questionDetail.userAnswerList.map(el => el.answerScore)
    },
    yMin() {
      return 0
    },
    yMax() {
      if (!this.questionDetail) {
        return 3
      }if (this.questionDetail.assessmentType == 5) {
        return 2
      } else {
        return 3
      }
    }
  },
  methods: {
    drawLine() {
      const that = this;
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          showContent: true,
          formatter: (params) => {
            const axisValue = params[0].axisValue
            const problemTitle = this.questionDetail.userAnswerList[params[0].dataIndex].problemTitle
            return axisValue + `${this.questionDetail.assessmentType == 1 ? '、' : ''}` + problemTitle
          }
        },
        xAxis: {
          type: 'category',
          axisLine: { show: false },
          axisTick: { show: false },
          data: this.xAxis,
          animation: false,
        },
        yAxis: [
          {
            type: 'value',
            axisLine: { show: false },
            axisTick: { show: false },
            min: this.yMin,
            max: this.yMax,
            minInterval: 1
          },
        ],
        series: [
          {
            type: 'bar',
            data: this.chartData,
            barWidth: "18",
            itemStyle: {
              color: '#0052D9',
            },
            lineStyle: {
              color: '#FF9950',
            },
            emphasis: {
              itemStyle: {
                borderColor: '#FF9950',
              },
            }
          },
        ]
      });
    },
  },
}
</script>
<style scoped lang='scss'>
.container {
  margin-left: 27px;
  height: 300px;
}
</style>