<template>
    <div class="list-container">
        <div class="list-scroll" v-for="item, index in questionList" :key="item.id">
            <!-- id 124和123单独判断说以后要大改先这么写 -->
            <div class="list-item" v-if="item.id !== 124 && item.id !== 123">
                <div class="title">{{ index + 1 }}. {{ item.problemTitle ?? '' }}</div>
                <div class="content" :title="contentText(item, isEdit)">
                    {{ contentText(item, isEdit) }}
                </div>
                <button class="edit" v-if="isEdit" @click.stop.prevent="onClickEdit(item)">
                    编辑
                    <img class="edit-icon" src="@imgs/user-main-baseline-arrow.png" alt="">
                </button>
            </div>
            <div class="list-item" v-if="item.id === 123">
                <div class="title">{{ index + 1 }}. {{ item.problemTitle ?? '' }}</div>
                <div class="content" style=" flex-grow: 0;flex-shrink: 0;" :title="contentText(item, isEdit)">
                    {{ contentText(item, isEdit) }}
                </div>
                <div class="title">{{ questionList[quest124Index].problemTitle ?? '' }}</div>
                <div class="content" :title="contentText(questionList[quest124Index], isEdit)">
                    {{ contentText(questionList[quest124Index], isEdit) }}
                </div>
                <button class="edit" v-if="isEdit" @click.stop.prevent="onClickEdit(item)">
                    编辑
                    <img class="edit-icon" src="@imgs/user-main-baseline-arrow.png" alt="">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isEdit: Boolean,
        data: Array,
        userAnswerList: Array
    },
    data() {
        return {
            questionList: [],
            childernQuestionList: []
        }
    },
    created() {

    },
    mounted() {

    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                this.questionList.splice(0, this.questionList.length)
                this.childernQuestionList.splice(0, this.childernQuestionList.length)
                this.$emit('update:userAnswerList', [])
                if (newVal.length == 0) {
                    return
                }
                const childQuestionId = newVal.reduce((acc, el) => {
                    el?.answerList.forEach(el1 => {
                        if (el1.havaChildren) {
                            acc.push(...el1.childrenProblemIds);
                        }
                    });
                    return acc;
                }, []);
                const reverseNewVal = [...newVal].reverse()
                reverseNewVal.forEach(el => {
                    if (childQuestionId.includes(el.id)) {
                        this.childernQuestionList.push(el)
                    } else {
                        this.questionList.push(el)
                    }
                })

                //设置编辑表单数据
                const userAnswerList = reverseNewVal.map(el => {
                    if (el.userAnswer) {
                        const { phone, ...userAnswer } = el.userAnswer
                        return {
                            problemId: el.id,
                            ...userAnswer
                        }
                    } else {
                        return {
                            problemId: el.id,
                            "singleChoiceAnswerId": null,
                            "manyChoiceAnswerId": [],
                            "addressProvince": "",
                            "addressCity": "",
                            "addressArea": "",
                            "detailedAddress": "",
                            "textInput": "",
                            "numberInput": 0,
                            "bigTextInput": "",
                            "timePeriod": "",
                            "timeInput": ""
                        }
                    }


                })
                this.$emit('update:userAnswerList', userAnswerList)
            },
            immediate: true
        }
    },
    computed: {
        quest124Index() { 
            return this.questionList.findIndex(el => el.id === 124)
        }
    },
    methods: {
        onClickEdit(item) {
            this.$emit('onClickEdit', {
                item: item,
                questionList: this.questionList,
                childernQuestionList: this.childernQuestionList,
                userAnswerList: this.userAnswerList
            })
        },
        contentText(item, type) {
            if (type) {
                const userAnswerListItem = this.userAnswerList.find(el => el.problemId == item.id)
                if (item.answerType == 1) {
                    let text = ''
                    const answer = item?.answerList.find(el => el.id == userAnswerListItem?.singleChoiceAnswerId)
                    text = answer?.answer
                    if (answer?.havaChildren) {

                        const questionChildren = this.childernQuestionList.filter(el => answer?.childrenProblemIds.includes(el.id)).map(el => this.contentText(el, true))
                        text += ' ' + questionChildren.join(' ')
                    }

                    return text ?? '待评估'
                } else if (item.answerType == 2) {
                    const userAnswerListItem = this.userAnswerList.find(el => el.problemId == item.id)
                    const list = item?.answerList.filter(el => userAnswerListItem?.manyChoiceAnswerId?.includes(el?.id))?.map(el => el.answer) ?? []
                    return list.length > 0  ? list?.join(' ') : '待评估'
                } else if (item.answerType == 3) {
                    return ""
                } else if (item.answerType == 4) {
                    return ""
                } else if (item.answerType == 5) {
                    return ""
                } else if (item.answerType == 6) {
                    return ""
                } else if (item.answerType == 7) {
                    return ""
                } else if (item.answerType == 8) {
                    return ""
                } else if (item.answerType == 9) {
                    return ""
                } else if (item.answerType == 10) {
                    return ""
                } else if (item.answerType == 11) {
                    return item?.answerList.find(el => el.id == userAnswerListItem?.singleChoiceAnswerId)?.answer ?? '待评估'
                }
            } else {
                if (item.answerType == 1) {
                    let text = ''
                    const answer = item?.answerList.find(el => el.id == item.userAnswer?.singleChoiceAnswerId)
                    text = answer?.answer
                    if (answer?.havaChildren) {
                        const questionChildren = this.childernQuestionList.filter(el => answer?.childrenProblemIds.includes(el.id)).map(el => this.contentText(el, false))
                        text += ' ' + questionChildren.join(' ')
                    }
                    return text ?? '待评估'
                } else if (item.answerType == 2) {
                    const list = item?.answerList.filter(el => item.userAnswer?.manyChoiceAnswerId?.includes(el?.id))?.map(el => el.answer) ?? []
                    return list.length > 0  ? list?.join(' ') : '待评估'
                } else if (item.answerType == 3) {
                    return ""
                } else if (item.answerType == 4) {
                    return ""
                } else if (item.answerType == 5) {
                    return ""
                } else if (item.answerType == 6) {
                    return ""
                } else if (item.answerType == 7) {
                    return ""
                } else if (item.answerType == 8) {
                    return ""
                } else if (item.answerType == 9) {
                    return ""
                } else if (item.answerType == 10) {
                    return ""
                } else if (item.answerType == 11) {
                    return item?.answerList.find(el => el.id == item.userAnswer?.singleChoiceAnswerId)?.answer ?? '待评估'
                }
            }
        }
    },
}
</script>
<style scoped lang='scss'>
.list-container {
    width: 100%;
    height: calc(100% - 45px);
    overflow: auto;
}

.list-scroll {
    .list-item {
        display: flex;
        align-items: center;
        width: 100%;
        transition: background-color 0.3s ease;
        padding: 0 25px;
        box-sizing: border-box;

        /* 过渡效果 */
        &:hover {
            background-color: #ECF2FF;
        }

        .title {
            height: 35px;
            font-size: 14px;
            color: #000;
            line-height: 35px;
            white-space: nowrap;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .content {
            margin-left: 30px;
            height: 35px;
            width: 100px;
            font-size: 14px;
            color: #5889EB;
            line-height: 35px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-grow: 1;
            flex-shrink: 1;
        }

        .edit {
            margin-left: auto;
            display: flex;
            align-items: center;
            min-width: 51px;
            height: 35px;
            font-size: 14px;
            color: #5889EB;
            line-height: 35px;
            flex-grow: 0;
            flex-shrink: 0;

            &-icon {
                width: 15px;
                height: 15px;
                margin-left: 8px;
            }
        }
    }
}
</style>