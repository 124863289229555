
<template>
    <div>
        <div class="title">{{ title ?? '' }}</div>
        <div class="info-container" v-if="questionDetail">
            <div class="score">
                <span class="number">{{ questionDetail?.assessmentScore ?? '' }}</span>
                <span class="unit">分</span>
            </div>
            <div class="status-list">
                <div class="status" :class="[colorStr]">
                    {{ this.questionDetail?.assessmentResult ?? '' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        questionDetail: Object,
        title: String
    },
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        colorStr() {
            if (!this.questionDetail) {
                return ''
            }
            if (this.questionDetail.assessmentType == 1) {
                if (this.questionDetail.assessmentResult == '非常好的睡眠质量') return 'green'
                if (this.questionDetail.assessmentResult == '良好的睡眠质量') return 'yellow'
                if (this.questionDetail.assessmentResult == '一般的睡眠质量') return 'blue'
                if (this.questionDetail.assessmentResult == '差的睡眠质量') return 'red'
            } else if (this.questionDetail.assessmentType == 2) {
                if (this.questionDetail.assessmentResult == '正常嗜睡') return 'green'
                if (this.questionDetail.assessmentResult == '轻度嗜睡') return 'yellow'
                if (this.questionDetail.assessmentResult == '中度嗜睡') return 'blue'
                if (this.questionDetail.assessmentResult == '严重嗜睡') return 'red'
            } else if (this.questionDetail.assessmentType == 3) {
                if (this.questionDetail.assessmentResult == '无焦虑症状') return 'green'
                if (this.questionDetail.assessmentResult == '轻度焦虑症状') return 'yellow'
                if (this.questionDetail.assessmentResult == '中度焦虑症状') return 'blue'
                if (this.questionDetail.assessmentResult == '严重焦虑症状') return 'red'
            } else if (this.questionDetail.assessmentType == 4) {
                if (this.questionDetail.assessmentResult == '无抑郁症状') return 'green'
                if (this.questionDetail.assessmentResult == '轻度抑郁症状') return 'yellow'
                if (this.questionDetail.assessmentResult == '中度抑郁症状') return 'blue'
                if (this.questionDetail.assessmentResult == '中重度抑郁症状') return 'purple'
                if (this.questionDetail.assessmentResult == '重度抑郁症状') return 'red'
            } else if (this.questionDetail.assessmentType == 5) {
                if (this.questionDetail.assessmentResult == '无明显身体症状') return 'green'
                if (this.questionDetail.assessmentResult == '轻度身体症状') return 'yellow'
                if (this.questionDetail.assessmentResult == '中度身体症状') return 'blue'
                if (this.questionDetail.assessmentResult == '重度身体症状') return 'red'
            } else {
                return ''
            }
        }
    },
    methods: {

    },
}
</script>
<style scoped lang='scss'>
.title {
    width: 196px;
    height: 28px;
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
    overflow: hidden;
}

.info-container {
    position: relative;
    height: 80px;
    width: 100%;

    .score {
        position: absolute;
        top: 10px;
        .number {
            height: 70px;
            font-size: 50px;
            font-weight: 500;
            color: #000000;
            line-height: 70px;
        }

        .unit {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
        }
    }

    .status-list {
        position: absolute;
        top: 42px;
        right: 0px;
        display: flex;
        flex-direction: column;
        .status {
            width: fit-content;
            padding: 0 8px;
            height: 22px;
            font-size: 12px;
            text-align: center;
            line-height: 22px;
            border-radius: 4px;
            border-width:  1px;
            border-style: solid;
        }

        .green {
            background-color: #F6FFED;
            color: #52C41A;
            border-color: #D9F7BE;
        }
        .yellow {
            background-color: #FFFBE6;
            color: #FAAD14;
            border-color: #FFF1B8;
        }
        .blue {
            background-color: #E6F4FF;
            color: #1677FF;
            border-color: #BAE0FF;
        }
        .purple{
            background-color: #F1E6FF;
            color: #9016FF;
            border-color: #D0BAFF;
        } 
        .red {
            background-color: #FFF1F0;
            color: #FF4D4F;
            border-color: #FFCCC7;
        }
    }
}
</style>