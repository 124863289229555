<template>
    <div class="card-no-data-container">
        <img class="card-no-data-img" src="@imgs/baseline-card-no-data.png"/>
        <p class="card-no-data-text">
            {{message}}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: '待评估'
        }
    },
}
</script>
<style scoped lang='scss'>
.card-no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 228px;
    width: 100%;
    box-sizing: border-box;

    .card-no-data-img {
        width: 191px;
        height: 188px;
    }

    .card-no-data-text {
        height: 25px;
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #2E6BE6;
        line-height: 25px;
    }
}
</style>