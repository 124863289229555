<template>
    <div class="baseline-container">
        <div class="content grid-container" :class="['']">
            <BaselineInfoCard class="grid-item" :type="1" :data="inspectionReport" :user="this.patientDetailModel?.model"
                @refresh="loadData"></BaselineInfoCard>
            <BaselineInfoCard class="grid-item" :type="2" :data="questionListData?.medicalHistoryProblems"
                :user="this.patientDetailModel?.model" @refresh="loadData"></BaselineInfoCard>
            <BaselineInfoCard class="grid-item" :type="3" :data="questionListData?.livingHabitProblems"
                :user="this.patientDetailModel?.model" @refresh="loadData"></BaselineInfoCard>
            <BaselineChartCard class="grid-item" :questionDetail="chartList[0]" :type="4" :user="this.patientDetailModel?.model" :data="questionListData?.sleepQualityList" @refresh="loadData"></BaselineChartCard>
            <BaselineChartCard class="grid-item" :questionDetail="chartList[1]" :type="6" :user="this.patientDetailModel?.model" :data="questionListData?.sleepyCouponList" @refresh="loadData"></BaselineChartCard>
            <BaselineChartCard class="grid-item" :questionDetail="chartList[2]" :type="7" :user="this.patientDetailModel?.model" :data="questionListData?.anxietyEmotionsList" @refresh="loadData"></BaselineChartCard>
            <BaselineChartCard class="grid-item" :questionDetail="chartList[3]" :type="8" :user="this.patientDetailModel?.model" :data="questionListData?.depressiveEmotionsList" @refresh="loadData" ></BaselineChartCard>
            <BaselineChartCard class="grid-item" :questionDetail="chartList[4]" :type="9" :user="this.patientDetailModel?.model" :data="questionListData?.physicalSymptomsList" @refresh="loadData" ></BaselineChartCard>
        </div>
    </div>
</template>

<script>
import BaselineInfoCard from './baseline-info-card/index.vue'
import BaselineChartCard from './baseline-chart-card/index.vue'
import { Empty } from 'element-ui'
export default {
    components: {
        BaselineChartCard,
        BaselineInfoCard,
        ElEmpty: Empty,
    },
    props: {
        patientDetailModel: Object
    },
    data() {
        return {
            chartList: [null, null, null, null, null],
            questionListData: null,
            inspectionReport: null,
            showEmpty: false
        }
    },
    mounted() {
        // this.load();
    },
    watch: {
        patientDetailModel: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.loadData();
                }
            },
            immediate: true
        }
    },
    methods: {
        async loadQuestionList() {
            try {
                const data = await this.$api.getQuestionnaireProblemList({ userId: this.patientDetailModel?.model?.id });
                this.questionListData = data;
            } catch (error) {
                console.log(error);
            }
        },
        async loadQuestionResultsList() {
            try {
                const data = await this.$api.getQuestionResultsList({ userId: this.patientDetailModel?.model?.id });
                data.forEach(el => {
                    if (el.assessmentType == 1) this.chartList.splice(0, 1, el);
                    if (el.assessmentType == 2) this.chartList.splice(1, 1, el);
                    if (el.assessmentType == 3) this.chartList.splice(2, 1, el);
                    if (el.assessmentType == 4) this.chartList.splice(3, 1, el);
                    if (el.assessmentType == 5) this.chartList.splice(4, 1, el);
                })
            } catch (error) {
                console.log(error);
            }
        },

        async loadInspectionReport() {
            try {
                const data = await this.$api.getInspectionReport({ userId: this.patientDetailModel?.model?.id });
                this.inspectionReport = data
            } catch (error) {
                console.log(error);
            }
        },

        async loadData() {
            //获取问卷数据,如果questionListData.completeState为0则整个页面都不显示
            await this.loadQuestionList();
            // if (!this.questionListData || this.questionListData?.completeState == null || this.questionListData?.completeState == 0) {
            //     this.showEmpty = true
            //     return
            // }
            this.loadQuestionResultsList();
            this.loadInspectionReport();
        }
    }

}
</script>

<style lang="scss" scoped>
.baseline-container {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.content {
    width: 100%;
    padding: 16px 0;
    box-sizing: border-box;

    .empty {
        margin: 100px auto;
    }

    :deep(.el-empty .el-empty__description p) {
        font-size: 30px;
    }
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
}

.grid-item {
    height: 362px;
    border-radius: 6px;
    border: 1px solid #E8E8E8;
}
</style>